export const API_URL = process.env.REACT_APP_API_URL;

export const InputSearchDelay = 700;

export enum PermissionsLabel {
  READ = 'Чтение',
  EDIT = 'Запись',
  CREATE = 'Создание',
  NO = 'Недоступно',
}

export enum Entities {
  EVENT = 'EVENT',
  USER = 'USER',
  DEVICE = 'DEVICE',
  VEHICLE = 'VEHICLE',
  BINDINGS = 'BINDINGS',
  ROLE = 'ROLE',
  GROUP = 'GROUP',
  SERVICE_MODE_HISTORY = 'SERVICE_MODE_HISTORY',
  SERVICE_MODE = 'SERVICE_MODE',
  SYSTEM_DRIVER_ACCOUNT = 'SYSTEM_DRIVER_ACCOUNT',
}

export enum Permissions {
  PERMISSION_VEHICLE_READ = `PERMISSION_VEHICLE_READ`,
  PERMISSION_VEHICLE_CREATE = `PERMISSION_VEHICLE_CREATE`,
  PERMISSION_VEHICLE_EDIT = 'PERMISSION_VEHICLE_EDIT',

  PERMISSION_EVENTS_READ = 'PERMISSION_EVENTS_READ',
  PERMISSION_EVENTS_READ_ONLY_ROLE = 'PERMISSION_EVENTS_READ_ONLY_ROLE',

  SYSTEM_GLOBAL_ADMIN = 'SYSTEM_GLOBAL_ADMIN',

  PERMISSION_ROLE_READ = `PERMISSION_ROLE_READ`,
  PERMISSION_ROLE_CREATE = `PERMISSION_ROLE_CREATE`,
  PERMISSION_ROLE_EDIT = 'PERMISSION_ROLE_EDIT',

  PERMISSION_DEVICE_READ = `PERMISSION_DEVICE_READ`,
  PERMISSION_DEVICE_CREATE = `PERMISSION_DEVICE_CREATE`,
  PERMISSION_DEVICE_EDIT = 'PERMISSION_DEVICE_EDIT',

  PERMISSION_USER_CREATE = `PERMISSION_USER_CREATE`,
  PERMISSION_USER_READ = `PERMISSION_USER_READ`,
  PERMISSION_USER_EDIT = 'PERMISSION_USER_EDIT',

  PERMISSION_BINDINGS_CREATE = `PERMISSION_BINDINGS_CREATE`,
  PERMISSION_BINDINGS_READ = `PERMISSION_BINDINGS_READ`,
  PERMISSION_BINDINGS_EDIT = 'PERMISSION_BINDINGS_EDIT',

  SYSTEM_DRIVER_ACCOUNT = 'SYSTEM_DRIVER_ACCOUNT',
  SYSTEM_SERVICE_ACCOUNT = 'SYSTEM_SERVICE_ACCOUNT',

  PERMISSION_SERVICE_MODE_CREATE = 'PERMISSION_SERVICE_MODE_CREATE',
  PERMISSION_SERVICE_MODE_READ = 'PERMISSION_SERVICE_MODE_READ',
  PERMISSION_SERVICE_MODE_EDIT = 'PERMISSION_SERVICE_MODE_EDIT',
  PERMISSION_SERVICE_MODE_HISTORY_READ = 'PERMISSION_SERVICE_MODE_HISTORY_READ',
}

export enum PermissionsStatus {
  NO_PERMISSION,
  READ,
  EDIT,
  CREATE,
}
