import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { EmailTemplate } from '../templates/types';
import { EmailTemplateForm } from './EmailTemplateForm';
import { EmailTemplateView } from './EmailTemplateView';

interface EmailTemplatesTableProps {
  templates: EmailTemplate[];
  sortField: keyof EmailTemplate | null;
  sortOrder: 'asc' | 'desc' | null;
  onRequestSort: (property: keyof EmailTemplate) => void;
  onToggleStatus: (id: number) => void;
  onEdit: (template: EmailTemplate) => void;
  onDelete: (template: EmailTemplate) => void;
  onAdd: () => void;
}

const EmailTemplatesTable: React.FC<EmailTemplatesTableProps> = ({
  templates,
  sortField,
  sortOrder,
  onRequestSort,
  onToggleStatus,
  onEdit,
  onDelete,
  onAdd,
}) => {
  const [hoveredColumn, setHoveredColumn] = useState<keyof EmailTemplate | null>(null);

  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isViewing, setIsViewing] = useState(false);

  const handleTooltipOpen = (key: string) => setActiveTooltip(key);
  const handleTooltipClose = () => setActiveTooltip(null);

  // Функция для обработки кликов по заголовкам для сортировки
  const handleSortClick = (field: keyof EmailTemplate) => {
    if (sortField === field) {
      // Если поле уже отсортировано, меняем направление сортировки
      onRequestSort(field);
    } else {
      // Если поле не отсортировано, сортируем по возрастанию
      onRequestSort(field);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!templates.length) return;

      setSelectedRowIndex((prev) => {
        let newIndex = prev ?? -1;

        if (event.key === 'ArrowDown') {
          newIndex = Math.min(newIndex + 1, templates.length - 1);
        } else if (event.key === 'ArrowUp') {
          newIndex = Math.max(newIndex - 1, 0);
        }

        if (newIndex !== prev) {
          const newRow = templates[newIndex];
          if (newRow) {
            setSelectedTemplate(newRow);
            setIsViewing(true);

            // Прокручиваем таблицу, если строка выходит за границы экрана
            document.querySelector(`#template-row-${newRow.id}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }

        return newIndex;
      });

      if (event.key === 'Enter' && selectedRowIndex !== null) {
        const selectedRow = templates[selectedRowIndex];
        if (selectedRow) {
          setSelectedTemplate(selectedRow);
          setIsViewing(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [templates, selectedRowIndex]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '92vh' }}>
      {/* Таблица */}
      <TableContainer sx={{ flexGrow: 1, maxHeight: '86vh', overflow: 'auto', marginTop: 2 }}>
        <Table size="small" stickyHeader sx={{ tableLayout: 'fixed', minWidth: '1000px' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#dad8d8', position: 'sticky', top: 0, zIndex: 2 }}>
              {[
                { label: 'Название', field: 'name' },
                { label: 'Автор', field: 'createdBy' },
                { label: 'Дата создания', field: 'createdAt' },
                { label: 'Тип шаблона', field: 'templateType' },
                { label: 'Дата изменения', field: 'lastModifiedAt' },
              ].map(({ label, field }) => (
                <TableCell
                  key={field}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#dad8d8',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  onClick={() => handleSortClick(field as keyof EmailTemplate)}
                  onMouseEnter={() => setHoveredColumn(field as keyof EmailTemplate)}
                  onMouseLeave={() => setHoveredColumn(null)}>
                  {label}
                  <span
                    style={{
                      display: 'inline-block',
                      position: 'absolute',
                      marginLeft: 10,
                      transition: 'transform 0.1s ease-in-out',
                    }}>
                    {sortField === field ? (
                      <Tooltip title="Сортировать">
                        <ArrowUpwardIcon
                          sx={{
                            fontSize: '1.5rem',
                            color: 'rgba(0, 0, 0, 0.54)',
                            transform: sortOrder === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.1s ease-in-out',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      hoveredColumn === field && (
                        <Tooltip title="Сортировать">
                          <ArrowUpwardIcon sx={{ fontSize: '1.5rem', opacity: 0.5 }} />
                        </Tooltip>
                      )
                    )}
                  </span>
                </TableCell>
              ))}
              <TableCell
                sx={{
                  width: '160px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#dad8d8',
                }}>
                Действия
              </TableCell>
              <TableCell
                sx={{
                  width: '60px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#dad8d8',
                }}>
                <Tooltip
                  title="Добавить"
                  open={activeTooltip === 'add'}
                  onOpen={() => handleTooltipOpen('add')}
                  onClose={handleTooltipClose}
                  disableInteractive>
                  <IconButton onClick={onAdd}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template, index) => (
              <TableRow
                key={template.id}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: selectedRowIndex === index ? '#d3d3d3' : 'inherit', // Серый цвет выделенной строки
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }, // Легкий эффект при наведении
                }}
                onClick={() => {
                  setSelectedTemplate(template);
                  setIsViewing(true);
                }}>
                <TableCell>{template.name}</TableCell>
                <TableCell>{template.createdBy?.firstName || '—'}</TableCell>
                <TableCell>{template.createdAt}</TableCell>
                <TableCell>{template.templateType?.name}</TableCell>
                <TableCell>{template.lastModifiedAt || '—'}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', width: '160px', textAlign: 'center' }}>
                  <Tooltip
                    title={template.actual ? 'Активный' : 'Активировать'}
                    disableInteractive
                    open={activeTooltip === `status-${template.id}`}
                    onOpen={() => handleTooltipOpen(`status-${template.id}`)}
                    onClose={handleTooltipClose}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onToggleStatus(template.id);
                        setIsViewing(false);
                      }}>
                      {template.actual ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Редактировать"
                    disableInteractive
                    open={activeTooltip === `edit-${template.id}`}
                    onOpen={() => handleTooltipOpen(`edit-${template.id}`)}
                    onClose={handleTooltipClose}>
                    <span
                      style={{ visibility: template.createdBy?.id === 0 ? 'hidden' : 'visible' }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsViewing(false);
                          onEdit(template);
                        }}
                        color="inherit">
                        <ModeEditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title="Удалить"
                    disableInteractive
                    open={activeTooltip === `delete-${template.id}`}
                    onOpen={() => handleTooltipOpen(`delete-${template.id}`)}
                    onClose={handleTooltipClose}>
                    <span
                      style={{ visibility: template.createdBy?.id === 0 ? 'hidden' : 'visible' }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsViewing(false);
                          onDelete(template);
                        }}
                        color="inherit">
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Окно редактора при клике на строку */}
      {selectedTemplate &&
        (isViewing ? (
          <EmailTemplateView
            template={selectedTemplate}
            onClose={() => setSelectedTemplate(null)}
          />
        ) : (
          <EmailTemplateForm
            template={selectedTemplate}
            onSave={() => {}}
            onClose={() => setSelectedTemplate(null)}
          />
        ))}

      {/* Пагинация зафиксирована внизу */}
      <div style={{ position: 'sticky', bottom: 0, zIndex: 2, backgroundColor: 'white' }}>
        {/* Сюда вставляется PaginationControls */}
      </div>
    </div>
  );
};

export default EmailTemplatesTable;
