export enum SortsTypes {
  asc = 'asc',
  desc = 'desc',
}

export enum SortTypes {
  USER_LAST_NAME = 'USER_LAST_NAME',
  NAMING = 'NAMING',
  TC = 'TC',
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  WHO_LINK = 'WHO_LINK',
  OPERATING_MODE = 'OPERATING_MODE',
  DATA_INSTALLATION = 'DATA_INSTALLATION',
  DRIVER = 'DRIVER',
  DATE_CREATE = 'DATE_CREATE',
  WHO_CREATE = 'WHO_CREATE',
  USER = 'USER',
  USER_ATTACH = 'USER_ATTACH',
  EMAIL = 'EMAIL',
  CAR_LINK = 'CAR_LINK',
  MARK = 'MARK',
  MODEL = 'MODEL',
  VIN = 'VIN',
  GOS_NUMBER = 'GOS_NUMBER',
  CREATED_AT = 'CREATED_AT',
  CREATED_BY = 'CREATED_BY',
  TYPE_OF_EVENT = 'TYPE_OF_EVENT',
  ALCOLOKS = 'ALCOLOKS',
  STATE = 'STATE',
  PROCESS = 'PROCESS',
  EXPIRES = 'EXPIRES',
  YEAR = 'YEAR',
  ROLE = 'ROLE',
  ACCESS = 'ACCESS',
  LEVEL = 'LEVEL',
  HANDLER = 'HANDLER',
  INITIATOR = 'INITIATOR',
  DATE_OCCURRENT = 'DATE_OCCURRENT',
}
