import 'react-router-dom';

import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';

import { RoutePaths } from '@shared/config/routePathsEnum';
import { routers } from '@shared/config/routers';
import { StorageKeys } from '@shared/const/storageKeys';
import type { ID } from '@shared/types/BaseQueryTypes';
import { cookieManager } from '@shared/utils/cookie_manager';

export type SelectedBranchState = {
  id: ID;
  name: string;
};

export interface AppStore {
  selectedBranchState: SelectedBranchState | null;
  auth: boolean;
  isAdmin: boolean;
  email: string | null;
  assignmentBranch: SelectedBranchState | null;
  authId: ID | null;
  permissions: string[];
  authError: string | null;
  setState: (data: {
    selectedBranchState?: SelectedBranchState;
    auth?: boolean;
    assignmentBranch?: SelectedBranchState;
    isAdmin?: boolean;
    email?: string;
    permissions?: string[];
    id?: ID;
  }) => void;
  logout: (showSnackbar?: boolean) => void;
  setAuthError: (message: string | null) => void;
}

const isNotUndefined = (value: unknown): value is undefined => {
  return typeof value !== 'undefined';
};

// TODO => убрать его когда кукки будут на бэке
const defaultData: Omit<AppStore, 'setState' | 'logout' | 'setAuthError'> = {
  selectedBranchState: null,
  assignmentBranch: null,
  permissions: [],
  auth: false,
  isAdmin: false,
  email: null,
  authId: null,
  authError: null,
};

export const appStore = create<AppStore>()((set, get) => ({
  ...defaultData,
  setState: (data) => {
    const state = get();
    const authId = data?.id || state.authId;
    const selectedBranchState = data?.selectedBranchState || state.selectedBranchState;
    const auth = isNotUndefined(data?.auth) ? data.auth : state.auth;
    const isAdmin = isNotUndefined(data?.isAdmin) ? data?.isAdmin : state.isAdmin;
    const email = isNotUndefined(data?.email) ? data?.email : null;
    const permissions = data?.permissions ? data?.permissions : state?.permissions;
    const assignmentBranch = data?.assignmentBranch
      ? data?.assignmentBranch
      : state?.assignmentBranch;
    const newState = {
      ...state,
      selectedBranchState,
      auth,
      isAdmin,
      email,
      permissions,
      assignmentBranch,
      authId,
    };
    set(newState);
  },
  logout: (showSnackbar) => {
    if (showSnackbar) {
      // Показываем сообщение с опцией закрытия вручную
      enqueueSnackbar('Сессия авторизации закончена, авторизуйтесь заново', {
        variant: 'error',
        persist: true, // Сообщение не исчезнет автоматически
        autoHideDuration: null,
      });
    }

    set({
      selectedBranchState: null,
      auth: false,
      email: null,
      isAdmin: false,
      assignmentBranch: null,
      authId: null,
      permissions: [],
    });

    cookieManager.removeAll();
    routers.navigate(RoutePaths.auth);
    localStorage.removeItem(StorageKeys.OFFICE);
  },
  setAuthError: (message) => set({ authError: message }),
}));
