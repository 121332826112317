/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPasswordApi } from '@pages/authorization/api/useResetPassworApi';
// import { RoutePaths } from '@shared/config/routePathsEnum';
import { StatusCode } from '@shared/const/statusCode';
import { ValidationMessages } from '@shared/validations/validation_messages';

import { Form, schema } from '../lib/validateReset';

export const useResetPassword = () => {
  // const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: {
      errors: { email },
    },
  } = useForm({
    defaultValues: {
      rememberMe: false,
    },
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useResetPasswordApi();

  const onSubmit = async (data: Form) => {
    mutate(data, {
      onSuccess: (response) => {
        if (response?.status === StatusCode.SUCCESS) {
          enqueueSnackbar('Ссылка для восстановления пароля отправлена на e-mail', {
            variant: 'success',
          });
          // navigate(RoutePaths.auth);
        } else {
          const errorMessage = response?.detail || ValidationMessages.defaultError;
          setError('username', {
            type: 'custom',
            message: errorMessage,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = (error as any)?.detail || ValidationMessages.defaultError;
        setError('username', {
          type: 'custom',
          message: errorMessage,
        });
      },
    });
  };

  const errorUsername = email ? email.message : '';

  return {
    handleSubmit: handleSubmit(onSubmit),
    isLoading,
    register,
    errorUsername,
    control,
  };
};
