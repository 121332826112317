import React, { useState } from 'react';

import { Table } from '@mui/material';

import { EmailTemplate } from '../templates/types';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import EmailTemplateEditor from './EmailTemplateEditor';
import EmailTemplatesTable from './EmailTemplatesTable';
import PaginationControls from './PaginationControls';

interface EmailTemplatesGridProps {
  templates: EmailTemplate[];
  onToggleStatus: (id: number) => void;
  onCreate: (template: Omit<EmailTemplate, 'id' | 'createdBy' | 'createdAt'>) => void;
  onEditSave: (template: Partial<EmailTemplate>) => void;
  onDelete: (id: number) => void;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onRequestSort: (property: keyof EmailTemplate) => void;
  sortField: keyof EmailTemplate | null;
  sortOrder: 'asc' | 'desc' | null;
}

export const EmailTemplatesGrid: React.FC<EmailTemplatesGridProps> = ({
  templates,
  onToggleStatus,
  onCreate,
  onEditSave,
  onDelete,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onRequestSort,
  sortField,
  sortOrder,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<EmailTemplate | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<EmailTemplate | null>(null);

  const handleOpenEditorForCreate = () => {
    setEditingTemplate(null);
    setEditorOpen(true);
  };

  const handleOpenEditorForEdit = (template: EmailTemplate) => {
    setEditingTemplate(template);
    setEditorOpen(true);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onRowsPerPageChange(event);
    onPageChange(null, 0);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '96vh' }}>
      {/* Таблица шаблонов */}
      <Table
        size="small"
        stickyHeader
        sx={{ tableLayout: 'fixed', minWidth: '1000px', flexGrow: 1 }}>
        <EmailTemplatesTable
          templates={templates}
          sortField={sortField}
          sortOrder={sortOrder}
          onRequestSort={onRequestSort}
          onToggleStatus={onToggleStatus}
          onEdit={handleOpenEditorForEdit}
          onDelete={(template) => {
            setTemplateToDelete(template);
            setDeleteDialogOpen(true);
          }}
          onAdd={handleOpenEditorForCreate}
        />
      </Table>

      {/* Пагинация */}
      <PaginationControls
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      {/* Окно редактора шаблонов */}
      <EmailTemplateEditor
        open={editorOpen}
        template={editingTemplate}
        onSave={editingTemplate ? onEditSave : onCreate}
        onClose={() => setEditorOpen(false)}
      />

      {/* Окно подтверждения удаления */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        template={templateToDelete}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          if (templateToDelete) onDelete(templateToDelete.id);
          setDeleteDialogOpen(false);
        }}
      />
    </div>
  );
};
