/**
 * @enum {string}
 */
export enum StorageKeys {
  ROLES_TABLE = 'ROLES_LIST_TABLE',
  USERS_TABLE = 'USERS_TABLE',
  CARS_TABLE_IN_GROUP = 'CARS_TABLE_IN_GROUP',
  ALCOLOCK_TABLE_IN_GROUP = 'ALCOLOCK_TABLE_IN_GROUP',
  USERS_TABLE_IN_GROUP = 'USERS_TABLE_IN_GROUP',
  VEHICLES_PAGE_TABLE_SORTS = 'VEHICLES_PAGE_TABLE_SORTS',
  BRANCH_TABLE_SORTS = 'BRANCH_TABLE_SORTS',
  ALCOLOCKS_TABLE = 'ALCOLOCKS_TABLE',
  OFFICE = 'OFFICE',
  ATTACHMENTS_TABLE_SORTS = 'ATTACHMENTS_TABLE_SORTS',
  EVENTS_TABLE_SORTS = 'EVENTS_TABLE_SORTS',
  AVTO_SERVICE_EVENTS_TABLE_SORTS = 'AVTO_SERVICE_EVENTS_TABLE_SORTS',
  NAVBAR_COLLOPS_MENU = 'NAVBAR_COLLOPS_MENU',
  HISTORY_TABLE_SORTS = 'HISTORY_TABLE_SORTS',
}

/**
 * @enum {string}
 */
export enum QueryKeys {
  IMAGE_URL_LIST = 'IMAGE_URL_LIST',
  IMAGE_ITEM = 'IMAGE_ITEM',
  IMAGE_LIST = 'IMAGE_LIST',
  AVATAR = 'AVATAR',
  IMAGE = 'IMAGE',
  EVENTS_COUNT = 'EVENTS_COUNT',
  ROLES_LIST_TABLE = 'ROLES_LIST_TABLE',
  ROLE_ITEM = 'ROLE_ITEM',
  ROLES_LIST = 'ROLES_LIST',
  ROLES_SELECT_LIST = 'ROLES_SELECT_LIST',
  USER_ITEM = 'USER_ITEM',
  USER_LIST_TABLE = 'USER_LIST_TABLE',
  CAR_ITEM = 'CAR_ITEM',
  VEHICLES_PAGE_TABLE = 'VEHICLES_PAGE_TABLE',
  BRANCH_LIST_SELECT = 'BRANCH_LIST_SELECT',
  BRANCH_LIST_TABLE = 'BRANCH_LIST_TABLE',
  BRANCH_ITEM = 'BRANCH_ITEM',
  ALKOLOCK_ITEM = 'ALKOLOCK_ITEM',
  ALKOLOCK_LIST_TABLE = 'ALKOLOCK_LIST_TABLE',
  ACCOUNT = 'ACCOUNT',
  USER_LIST = 'USER_LIST',
  DRIVER_LIST = 'DRIVER_LIST',
  CAR_LIST = 'CAR_LIST',
  ATTACHMENT_LIST = 'ATTACHMENT_LIST',
  ALCOLOCK_LIST = 'ALCOLOCK_LIST',
  EVENTS_LIST_HISTORY = 'EVENTS_LIST_HISTORY',
  EVENTS_LIST_TABLE = 'EVENTS_LIST_TABLE',
  EVENTS_TYPE_LIST = 'EVENTS_TYPE_LIST',
  EVENTS_LIST = 'EVENTS_LIST',
  EVENTS_ITEM = 'EVENTS_ITEM',
  AVTOSERVISE_EVENTS_ITEM = 'AVTOSERVISE_EVENTS_ITEM',
  AUTO_SERVICE_EVENTS_LIST = 'AUTO_SERVICE_EVENTS_LIST',
  AUTO_SERVICE_COUNT_EVENTS_LIST = 'AUTO_SERVICE_COUNT_EVENTS_LIST',
  MARKS_CAR = 'MARKS_CAR',
  BACKEND_VERSION = 'BACKEND_VERSION',
  USER_PHOTOS = 'USER_PHOTOS',
  DRIVER_ATTACHMENTS = 'DRIVER_ATTACHMENTS',
  HISTORY_LIST_TABLE = 'HISTORY_LIST_TABLE',
}
