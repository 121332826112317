import { Link, NavLink } from 'react-router-dom';

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Button, Stack, Switch, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { MenuButton } from '@features/menu_button';
import { NavbarBranchSelect } from '@features/nav_bar_branch_select';
import { PasswordForm } from '@features/password_form';
import { AccountApi } from '@shared/api/baseQuerys';
import { RoutePaths } from '@shared/config/routePathsEnum';
import { QueryKeys, StorageKeys } from '@shared/const/storageKeys';
import { testids } from '@shared/const/testid';
import { useConfiguredQuery } from '@shared/hooks/useConfiguredQuery';
import { useLocalStorage } from '@shared/hooks/useLocalStorage';
import { useToggle } from '@shared/hooks/useToggle';
import { Logo } from '@shared/images/logo';
import { appStore } from '@shared/model/app_store/AppStore';
import { Popup } from '@shared/ui/popup';

import { breakpoints } from '../breakpoints';
import { NAV_LINKS, frontendVersion } from '../config/const';
import { tooltipStyle } from '../config/styles';
import { useNavBar } from '../hooks/useNavBar';
import style from './NavBar.module.scss';

export const NavBar = () => {
  const [open, toggle, close] = useToggle();
  const { state, setItemState } = useLocalStorage({
    key: StorageKeys.NAVBAR_COLLOPS_MENU,
    value: false,
  });
  const [isSliderActive, setSliderActive] = useToggle(true);
  const { length, permissionsFilter, email } = useNavBar();
  const isMobile = useMediaQuery(breakpoints.mobile);
  const isTablet = useMediaQuery(breakpoints.tablet);

  const handleCollops = () => {
    setItemState(!state);
  };

  const { data } = useConfiguredQuery([QueryKeys.BACKEND_VERSION], AccountApi.getBackandVersion, {
    triggerOnBranchChange: false,
  });

  const backendVersion = data?.data as string;
  const isAdmin = appStore().isAdmin;

  // Определяем, нужно ли свернуть навигацию (для мобильных/планшетов или при ручном сворачивании)
  const isCollapsed = state || isMobile || isTablet;

  return (
    <>
      <div
        className={`${isCollapsed ? style.navBarCollops : style.navBarOpen} ${style.wrapper} ${isMobile || isTablet ? style.mobileNav : ''}`}>
        {!(isMobile || isTablet) && (
          <div className={`${style.logo} ${!isCollapsed && style.between}`}>
            <Link to="/events">
              <Logo className={style.img} />
            </Link>
            {!isCollapsed && <span className={style.logoText}>Лазерные системы</span>}
          </div>
        )}

        <div className={`${isCollapsed ? style.navBarCollops : style.navBarOpen} ${style.navBar}`}>
          <div className={style.navBarWrapper}>
            <NavbarBranchSelect
              tooltipProps={{ slotProps: tooltipStyle, placement: 'right' }}
              isCollops={isCollapsed}
            />

            <div className={style.links}>
              {NAV_LINKS.filter(permissionsFilter).map((link, i) => {
                if (isSliderActive && link.path === RoutePaths.messages) {
                  return null;
                }

                if (!isSliderActive && link.path !== RoutePaths.messages) {
                  return null;
                }

                const notification = link.path === RoutePaths.autoService;

                return (
                  <Tooltip
                    placement="right"
                    slotProps={tooltipStyle}
                    disableHoverListener={isCollapsed}
                    title={link.name}
                    key={link.path}>
                    <NavLink
                      key={link.path}
                      data-testid={testids.widget_navbar.NAVBAR_LINK[i]}
                      className={`${isCollapsed ? style.center : style.between} ${style.navLink}`}
                      to={link.path}>
                      {!isCollapsed && <span>{link.name}</span>}
                      <span>{link.icon}</span>
                      {notification && <span className={style.notifications}>{length}</span>}
                    </NavLink>
                  </Tooltip>
                );
              })}
            </div>
          </div>

          <div>
            {isAdmin && !(isMobile || isTablet) && (
              <div className={style.sliderContainer} style={{ marginBottom: '16px' }}>
                {!isCollapsed && <Typography variant="body2">Переключить вкладки</Typography>}
                <Switch
                  checked={isSliderActive}
                  onChange={setSliderActive}
                  name="toggleTabs"
                  color="primary"
                />
              </div>
            )}

            {!isCollapsed && !(isMobile || isTablet) && (
              <div className={style.versionContainer}>
                <div className={style.versionItem}>
                  <Typography variant="inherit" className={style.versionText}>
                    frontend: v {frontendVersion}
                  </Typography>
                </div>
                <div className={style.versionItem}>
                  <Typography variant="inherit" className={style.versionText}>
                    backend: v {backendVersion}
                  </Typography>
                </div>
              </div>
            )}

            <Stack gap={1}>
              <MenuButton
                tooltipProps={{ slotProps: tooltipStyle, placement: 'right' }}
                collops={isCollapsed}
                email={email}
                close={close}
                toggleModal={toggle}
              />

              {!(isMobile || isTablet) && (
                <Button
                  onClick={handleCollops}
                  className={`${style.navBarButton} ${!isCollapsed && style.between}`}>
                  {!isCollapsed && (
                    <span className={`${isCollapsed && style.textCollops} ${style.text}`}>
                      Скрыть
                    </span>
                  )}
                  <span className={`${isCollapsed && style.collops} ${style.openedCollops}`}>
                    <Tooltip title={isCollapsed ? 'Раскрыть' : 'Свернуть'}>
                      <ArrowBackIosNewOutlinedIcon
                        className={isCollapsed ? style.rotateIcon : ''}
                      />
                    </Tooltip>
                  </span>
                </Button>
              )}
            </Stack>
          </div>
        </div>
      </div>

      <Popup
        isOpen={open}
        toggleModal={toggle}
        closeonClickSpace={false}
        body={<PasswordForm close={close} />}
        onCloseModal={close}
      />
    </>
  );
};
