import style from './Logo.module.scss';

interface LogoProps {
  testid?: string;
}

export const Logo = ({ testid }: LogoProps) => {
  return (
    <div className={style.svg} data-testid={testid}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1681.000000pt"
        height="591.000000pt"
        viewBox="0 0 1681.000000 591.000000"
        preserveAspectRatio="xMidYMid meet">
        <g
          transform="translate(0.000000,591.000000) scale(0.100000,-0.100000)"
          fill="#070707"
          stroke="none">
          <g fill={'#EC0026'}>
            <path
              d="M1482 3323 l3 -1108 27 -55 c39 -78 559 -598 633 -632 l50 -23 643
          -3 642 -3 0 331 0 331 -322 -3 c-312 -3 -325 -4 -373 -26 -38 -17 -113 -86
          -320 -292 -149 -149 -278 -270 -286 -270 -8 0 -20 5 -27 12 -9 9 -12 340 -12
          1430 l0 1418 -330 0 -330 0 2 -1107z"
            />
            <path
              d="M2643 4416 c-94 -30 -179 -110 -210 -199 -16 -47 -18 -94 -18 -502
          l0 -450 24 -53 c15 -33 45 -72 83 -107 53 -49 69 -58 171 -90 62 -19 687 -210
          1391 -424 703 -214 1282 -395 1287 -402 4 -7 5 -17 3 -21 -3 -4 -369 -8 -815
          -8 l-809 0 0 -330 0 -330 743 2 742 3 56 28 c66 32 132 99 162 165 22 46 22
          57 22 507 l0 460 -33 67 c-32 65 -81 117 -140 147 -13 7 -636 199 -1385 427
          -749 227 -1373 418 -1386 424 -19 9 -21 13 -11 25 11 13 199 15 1486 15 l1474
          0 0 330 0 330 -1397 -1 c-1121 0 -1406 -3 -1440 -13z"
            />
          </g>
          <path
            d="M9033 4186 c-168 -39 -252 -122 -269 -269 l-7 -57 111 0 110 0 7 33
          c18 83 75 116 205 117 153 0 200 -29 200 -124 0 -57 -20 -88 -68 -108 -15 -6
          -82 -13 -149 -16 l-123 -5 0 -87 0 -88 138 -4 c115 -3 143 -7 168 -22 44 -29
          53 -46 54 -101 0 -94 -46 -126 -190 -133 -113 -5 -172 8 -212 48 -20 21 -28
          38 -28 64 l0 36 -118 0 -118 0 7 -58 c19 -169 120 -249 345 -274 170 -19 341
          9 437 73 87 57 132 176 109 286 -16 79 -49 123 -116 159 l-58 31 31 13 c45 18
          108 92 121 142 16 57 7 144 -19 195 -29 59 -91 107 -169 133 -86 29 -304 37
          -399 16z"
          />
          <path
            d="M6782 4163 c-4 -10 -86 -241 -182 -513 l-176 -495 110 -3 c60 -1 112
          1 116 5 4 4 72 196 151 426 78 229 146 414 149 410 4 -5 69 -190 145 -413 75
          -223 140 -410 142 -417 4 -10 38 -13 125 -13 120 0 120 0 114 23 -3 12 -84
          243 -179 512 l-174 490 -167 3 c-152 2 -167 1 -174 -15z"
          />
          <path
            d="M7806 3758 c-82 -233 -164 -465 -183 -516 l-33 -93 117 3 116 3 35
          103 34 102 223 0 223 0 34 -102 35 -103 121 -3 c67 -1 122 1 122 5 0 4 -81
          235 -179 513 l-179 505 -169 3 -168 2 -149 -422z m394 6 l69 -204 -154 0 -154
          0 74 216 c41 119 75 219 75 222 1 21 30 -54 90 -234z"
          />
          <path
            d="M9840 3665 l0 -515 385 0 385 0 0 95 0 95 -265 0 -265 0 0 120 0 120
          265 0 265 0 0 95 0 95 -265 0 -265 0 0 110 0 110 265 0 265 0 0 95 0 95 -385
          0 -385 0 0 -515z"
          />
          <path
            d="M10860 3665 l0 -515 120 0 120 0 0 164 0 165 208 3 c203 3 208 4 264
          31 67 33 111 80 147 157 23 48 26 68 26 160 0 95 -3 111 -29 168 -37 80 -108
          146 -179 167 -40 12 -114 15 -364 15 l-313 0 0 -515z m584 304 c40 -31 56 -71
          56 -139 0 -68 -16 -108 -56 -139 -25 -19 -40 -21 -185 -21 l-159 0 0 160 0
          160 159 0 c145 0 160 -2 185 -21z"
          />
          <path
            d="M11930 3665 l0 -515 120 0 120 0 0 215 0 215 225 0 225 0 0 -215 0
          -215 120 0 120 0 0 515 0 515 -120 0 -120 0 0 -200 0 -200 -225 0 -225 0 0
          200 0 200 -120 0 -120 0 0 -515z"
          />
          <path
            d="M13110 3665 l0 -515 298 0 c357 0 379 4 458 84 69 69 96 140 96 257
          0 149 -44 249 -138 309 -65 41 -122 50 -309 50 l-165 0 0 165 0 165 -120 0
          -120 0 0 -515z m569 -31 c50 -36 68 -155 33 -224 -29 -58 -66 -70 -224 -70
          l-138 0 0 155 0 155 153 0 c125 0 157 -3 176 -16z"
          />
          <path d="M14070 3665 l0 -515 120 0 120 0 0 515 0 515 -120 0 -120 0 0 -515z" />
          <path
            d="M14560 3665 l0 -515 385 0 385 0 0 95 0 95 -265 0 -265 0 0 120 0
          120 265 0 265 0 0 95 0 95 -265 0 -265 0 0 110 0 110 265 0 265 0 0 95 0 95
          -385 0 -385 0 0 -515z"
          />
          <path d="M14070 3670 l0 -520 125 0 125 0 0 520 0 520 -125 0 -125 0 0 -520z" />
          <path
            d="M14550 3670 l0 -520 395 0 395 0 0 100 0 100 -270 0 -270 0 0 115 0
115 270 0 270 0 0 95 0 95 -270 0 -270 0 0 110 0 110 270 0 270 0 0 100 0 100
-395 0 -395 0 0 -520z"
          />
          <path
            d="M6790 2539 c-119 -21 -242 -102 -297 -196 -45 -77 -64 -158 -70 -293
-13 -317 109 -510 356 -561 87 -18 302 -6 376 20 152 55 255 197 255 353 l0
38 -125 0 c-123 0 -125 0 -125 -22 0 -119 -95 -201 -230 -201 -121 0 -203 59
-237 171 -24 77 -23 268 1 332 42 113 102 159 217 168 142 10 228 -54 246
-183 l6 -45 125 0 125 0 -6 59 c-18 171 -115 291 -277 342 -76 24 -253 34
-340 18z"
          />
          <path
            d="M9100 2534 c-195 -42 -308 -165 -347 -376 -17 -96 -12 -261 11 -347
46 -169 154 -277 317 -316 75 -17 255 -20 331 -4 81 17 170 64 216 113 64 69
92 135 106 249 l6 48 -127 -3 -127 -3 -8 -43 c-10 -57 -42 -112 -82 -140 -62
-42 -199 -47 -273 -9 -95 50 -140 193 -121 388 16 167 87 247 229 257 149 10
249 -70 249 -200 l0 -28 126 0 127 0 -6 67 c-8 79 -49 173 -101 230 -100 111
-325 161 -526 117z"
          />
          <path
            d="M7610 2010 l0 -521 157 3 157 3 196 380 195 380 3 -382 2 -383 120 0
120 0 0 520 0 521 -156 -3 -157 -3 -191 -372 -191 -372 -3 375 -2 374 -125 0
-125 0 0 -520z"
          />
          <path
            d="M9840 2430 l0 -100 170 0 170 0 0 -420 0 -420 125 0 125 0 0 420 0
420 170 0 170 0 0 100 0 100 -465 0 -465 0 0 -100z"
          />
          <path
            d="M10920 2010 l0 -520 390 0 390 0 0 100 0 100 -265 0 -265 0 0 120 0
120 265 0 265 0 0 95 0 95 -265 0 -265 0 0 105 0 105 265 0 265 0 0 100 0 100
-390 0 -390 0 0 -520z"
          />
          <path
            d="M11940 2010 l0 -520 115 0 115 0 2 387 3 387 104 -263 105 -262 125
3 125 3 103 260 102 260 1 -387 0 -388 115 0 115 0 0 520 0 520 -172 -2 -171
-3 -106 -282 c-58 -156 -108 -283 -111 -283 -3 0 -54 128 -115 285 l-110 285
-172 0 -173 0 0 -520z"
          />
          <path
            d="M13310 2009 l0 -520 318 3 c308 3 318 4 369 27 65 29 124 91 149 157
15 39 19 75 19 174 0 117 -2 129 -28 182 -32 64 -80 111 -146 142 -40 18 -70
21 -238 24 l-193 4 0 164 0 164 -125 0 -125 0 0 -521z m582 -46 c29 -29 33
-40 36 -102 3 -50 0 -78 -13 -104 -26 -56 -61 -67 -219 -67 l-136 0 0 156 0
156 150 -4 150 -3 32 -32z"
          />
          <path d="M14280 2010 l0 -520 120 0 120 0 0 520 0 520 -120 0 -120 0 0 -520z" />
        </g>
      </svg>
    </div>
  );
};
