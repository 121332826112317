import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';

interface EmailTemplatesSearchProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
}

const EmailTemplatesSearch: React.FC<EmailTemplatesSearchProps> = ({
  searchQuery,
  onSearchChange,
}) => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const handleTooltipOpen = (key: string) => setActiveTooltip(key);
  const handleTooltipClose = () => setActiveTooltip(null);
  return (
    <TextField
      variant="outlined"
      placeholder="Поиск"
      value={searchQuery}
      onChange={(e) => onSearchChange(e.target.value)}
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title="Очистить"
              open={activeTooltip === 'add'}
              onOpen={() => handleTooltipOpen('add')}
              onClose={handleTooltipClose}
              disableInteractive>
              <IconButton onClick={() => onSearchChange('')}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailTemplatesSearch;
