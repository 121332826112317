export enum RoutePaths {
  all = '*',
  root = '/',
  auth = '/authorization',
  events = '/events',
  users = '/users',
  roles = '/roles',
  roles_new = '/roles_new',
  groups = '/groups',
  tc = '/tc',
  alkozamki = '/alkozamki',
  attachments = '/attachments',
  autoService = '/auto_service',
  historyAutoService = '/historyAutoService',
  changePassword = '/changePassword',
  forgetPassword = '/forgetPassword',
  resetPassword = '/resetPassword',
  messages = '/messages',
}
